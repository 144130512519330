import React, { createContext, useState } from "react"

const AppContext = createContext(null)

const AppProvider = ({ children }) => {
  const [theme, setTheme] = useState("agnostic")
  const [mobileInputFocus, setMobileInputFocus] = useState(false)
  const [activeHeaderIndex, setActiveHeaderIndex] = useState()
  const [altThankYouMsg, setAltThankYouMsg] = useState(false)
  const [exitLink, setExitLink] = useState(undefined)

  return (
    <AppContext.Provider
      value={{
        theme,
        setTheme,
        mobileInputFocus,
        setMobileInputFocus,
        activeHeaderIndex,
        setActiveHeaderIndex,
        altThankYouMsg,
        setAltThankYouMsg,
        exitLink,
        setExitLink,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext }
